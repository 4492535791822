import { page } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type PageEvent = { previousPage?: string }

export default function viewSearchResultsPage(data?: PageEvent) {
  const previousPage = data?.previousPage

  page({
    name: 'Search Results Page',
    referrer: previousPage,
  })
}
