import { trackOnClient } from 'hooks/useAnalytics/AnalyticsTransmitter'

import { AlgoliaListing } from 'types/externalData'
import { Listing } from 'types/Listing.type'

export type RecommendedListingClick = Partial<Listing | AlgoliaListing> & {
  position: number
  price: number
}

export default function clickRecommendedListing(data: RecommendedListingClick) {
  const { price, position } = data
  const productId = data.objectId || data.objectID || ''
  const reviewScore = data.averageRating || data['Average Rating'] || ''
  const reviewCount = data.numberOfReviews || data['Number of Reviews'] || ''

  trackOnClient('Similar Listings Product Clicked', {
    productId,
    reviewCount,
    reviewScore,
    price,
    position,
  })
}
