import { trackOnClient } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type AbandonedCart = {
  productId: string
  lastAbandonedUrl: string
}

export default function abandonedCart(data: AbandonedCart) {
  const { productId, lastAbandonedUrl, ...rest } = data

  trackOnClient('Abandoned Cart Email Sent', {
    product_id: productId,
    url: lastAbandonedUrl,
    ...rest,
  })
}
