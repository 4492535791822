import { trackOnServer } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type ErrorData = {
  errorResponse: string
  errorType: string
  url?: string
  productId: string
  page?: string
}

export default function httpErrorOnPage(data: ErrorData) {
  trackOnServer(
    'Error Returned',
    {
      error_type: data.errorType,
      error_response: data.errorResponse,
      url: data.url,
      product_id: data.productId,
    },
    {
      withoutPrefix: true,
    },
  )
}
