import { trackOnClient } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type ProductClickedEvent = {
  'productId'?: string | number
  'reviewCount'?: string | number
  'reviewScore'?: string | number
  'price'?: string | number | null
  'position'?: string | number | null
  'city'?: string
  'country'?: string
  'name'?: string
  'query'?: string
  'region'?: string
  'searchPageNumber'?: string | number | null
  'objectId'?: string
  'objectID'?: string
  'category'?: string
  'propertyType'?: string
  'Property Type'?: string
  'headline'?: string
  'Headline'?: string
  'averageRating'?: string | number
  'Average Rating'?: string | number
  'numberOfReviews'?: string | number
  'Number of Reviews'?: string | number
  'City'?: string
  'state'?: string
  'State'?: string
  'Country'?: string
}

export default function clickListing(listing: ProductClickedEvent) {
  const { query, price, position, searchPageNumber } = listing

  const productId =
    listing.productId || listing.objectId || listing.objectID || ''
  const category =
    listing.category || listing.propertyType || listing['Property Type'] || ''
  const name = listing.name || listing.headline || listing.Headline || ''
  const reviewScore =
    listing.reviewScore ||
    listing.averageRating ||
    listing['Average Rating'] ||
    ''
  const reviewCount =
    listing.reviewCount ||
    listing.numberOfReviews ||
    listing['Number of Reviews'] ||
    ''
  const city = listing.city || listing.City || ''
  const region = listing.region || listing.state || listing.State || ''
  const country = listing.country || listing.Country || ''

  trackOnClient('Product Clicked', {
    city,
    country,
    name,
    position: position ?? null,
    price: price ?? null,
    productId,
    query: query ?? '',
    region,
    reviewCount,
    reviewScore,
    searchPageNumber: searchPageNumber ?? null,
    product: {
      category,
    },
  })
}
