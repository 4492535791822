import { trackOnClient } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type ClearFilters = {
  amenities?: string[]
  bathrooms?: number
  bedrooms?: number
  beds?: number
  priceMax?: number
  priceMin?: number
  propertyType?: string[]
}

export type InitializedFrom =
  | 'Calendar Modal'
  | 'No Results'
  | 'Filters Modal'
  | 'Search Navigation'
  | 'No Results'

export type ClearSearchData = {
  initializedFrom: InitializedFrom
  clickedFrom: 'Clear Dates' | 'Clear Filters'
  selectedDates?: { start: string; end: string }
} & ClearFilters

export default function clearSearchClicked(data: ClearSearchData) {
  const { selectedDates, ...rest } = data

  const travelStart = selectedDates?.start.replace(
    /(\d{4})(\d{2})(\d{2})/g,
    '$1-$2-$3 ',
  )
  const travelEnd = selectedDates?.end.replace(
    /(\d{4})(\d{2})(\d{2})/g,
    '$1-$2-$3 ',
  )

  trackOnClient('Clear Search Clicked', {
    ...rest,
    travelStart,
    travelEnd,
  })
}
