import { page } from 'hooks/useAnalytics/AnalyticsTransmitter'

import promotionData from 'constants/promotionData'

export type PageEvent = {
  previousPage: string
  promotionId: string
}

export default function viewPromotionPage(data: PageEvent) {
  const previousPage = data.previousPage

  const promotion = promotionData.find(
    (promotion) => promotion.id === data.promotionId,
  )

  if (promotion !== undefined) {
    const eventName = promotion.analyticsEventName

    page({
      name: eventName,
      referrer: previousPage,
    })
  }
}
