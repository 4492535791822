import { StripeError } from '@stripe/stripe-js'

import { trackOnServer } from 'hooks/useAnalytics/AnalyticsTransmitter'

export default function paymentFailed(
  data:
    | StripeError
    | {
        code?: string
        decline_code?: string
        message?: string
        payment_method?: {
          type?: string
          id?: string
        }
        payment_intent?: {
          amount?: number
          id?: string
        }
        setup_intent?: {
          id?: string
        }
        requestId?: string
        request_log_url?: string
      },
) {
  trackOnServer(
    'Payment Failed',
    {
      code: data.code,
      declineCode: data.decline_code,
      message: data.message,
      paymentMethodType: data.payment_method?.type,
      paymentMethodId: data.payment_method?.id,
      amount: data.payment_intent?.amount,
      setupIntentId: data.setup_intent?.id,
      paymentIntentId: data.payment_intent?.id,
      // @ts-ignore
      requestId: data.requestId,
      // @ts-ignore
      requestLogUrl: data.request_log_url,
    },
    {
      withoutPrefix: true,
    },
  )
}
