import { trackOnServer } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type ResponseDelayedTypes =
  | 'Quote Request'
  | 'Availability Summary Request'
  | 'Booking Request'

type Data = {
  responseTime: string
  response: ResponseDelayedTypes
  listingId: string
  error?: string | Response | TypeError | Error
}

export default function reportDelayedRequest(data: Data) {
  const { responseTime, response, listingId, error } = data
  const eventData = {
    productId: listingId || '',
    responseTime,
    response,
  }

  let errorString = ''
  if (error instanceof Response) {
    errorString = error.statusText
  } else if (error instanceof TypeError || error instanceof Error) {
    errorString = error.message
  } else if (error) {
    errorString = error
  }

  if (error) {
    trackOnServer(
      'Response Delayed',
      {
        ...eventData,
        errorDescription: errorString,
      },
      {
        withoutPrefix: true,
      },
    )
  } else {
    trackOnServer('Response Delayed', eventData, {
      withoutPrefix: true,
    })
  }
}
