import { trackOnClient } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type Product = {
  section: string
  clicked?: string
  response?: string | null
  listingId: string
  action?: 'Open' | 'Close'
}

export default function clickProductSection(data: Product) {
  const { listingId: productId, ...rest } = data

  trackOnClient('Product Section Clicked', {
    productId,
    ...rest,
  })
}
