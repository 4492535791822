import httpErrorOnPage from './httpErrorOnPage'

export type ErrorData = {
  errorResponse: string
  url?: string
  productId: string
  page?: string
}

export default function invalidQuote(data: ErrorData) {
  httpErrorOnPage({
    ...data,
    errorType: 'invalid quote',
  })
}
