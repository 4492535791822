import { identify } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type GuestIncentiveSubmitedData = {
  firstName: string
  lastName: string
  zipCode?: string
  emailAddress: string
  phoneNumber: string
}

export default function identifyOnGuestIncentiveSubmit(
  data: GuestIncentiveSubmitedData,
) {
  const { emailAddress, phoneNumber, ...rest } = data

  identify(emailAddress, {
    ...rest,
    email: emailAddress,
    phone: phoneNumber,
  })
}
