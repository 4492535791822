import dayjs from 'dayjs'

import { BookingDates } from 'context/BookingContext'

import { trackOnClient } from 'hooks/useAnalytics/AnalyticsTransmitter'

import DatesConstants from 'constants/dates'

export type RecommendedListingsView = {
  listingId: string
  bookingDates?: BookingDates
}

export default function viewRecommendedListings(data: RecommendedListingsView) {
  const { bookingDates, listingId } = data
  const startDate = bookingDates?.start ?? ''
  const endDate = bookingDates?.end ?? ''
  const productId = listingId || ''
  const travelStart = startDate
    ? dayjs(startDate).format(DatesConstants.SPLIT_BY_DASH)
    : startDate
  const travelEnd = endDate
    ? dayjs(endDate).format(DatesConstants.SPLIT_BY_DASH)
    : endDate

  trackOnClient('Similar Listings Viewed', {
    productId,
    travelStart,
    travelEnd,
  })
}
