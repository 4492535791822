import {
  trackOnClient,
  trackOnServer,
} from 'hooks/useAnalytics/AnalyticsTransmitter'

export type ProductPurchased = {
  product_id: string
  name: string
  price: number
  value: number
  currency: 'USD'
  travel_start: string
  travel_end: string
  review_score: number
  review_count: number
  num_adults: number | ''
  num_children: number | ''
  num_infants: number | ''
  num_pets: number | ''
  city: string
  region: string
  country: string
  category: string
  order_id: string
  subtotal: number
  total: number | ''
  tax: number | ''
  discount: number | ''
  quantity: number
  email: string
  test_variant: string
  guest_service_fee_amount: number | ''
  guest_service_fee_percent: number | ''
  paymentmethod_type: string
  wallet_type?: string
}

const EVENT_NAME = 'Product Purchased'

export default function productPurchased(data: ProductPurchased) {
  trackOnClient(EVENT_NAME, data)
  trackOnServer(EVENT_NAME, data)
}
