import abandonedCart from './events/abandonedCart'
import clearSearchClicked from './events/clearSearchClicked'
import clickDismissEvolveDifference from './events/clickDismissEvolveDifference'
import clickListing from './events/clickListing'
import clickProductSection from './events/clickProductSection'
import clickRecommendedListing from './events/clickRecommendedListing'
import experimentViewed from './events/experimentViewed'
import httpErrorOnPage from './events/httpErrorOnPage'
import identifyOnGuestIncentiveSubmit from './events/identifyOnGuestIncentiveSubmit'
import productPurchased from './events/productPurchased'
import reportDelayedRequest from './events/reportDelayedRequest'
import trackOnGuestIncentiveSubmit from './events/trackOnGuestIncentiveSubmit'
import viewNotFoundPage from './events/viewNotFoundPage'
import viewPromotionPage from './events/viewPromotionPage'
import viewRecommendedListings from './events/viewRecommendedListings'
import viewSearchMap from './events/viewSearchMap'
import viewSearchResultsPage from './events/viewSearchResultsPage'
import paymentFailed from './events/paymentFailed'
import invalidQuote from './events/invalidQuote'

const ANALYTICS_METHODS = {
  abandonedCart,
  clearSearchClicked,
  clickDismissEvolveDifference,
  clickListing,
  clickProductSection,
  clickRecommendedListing,
  experimentViewed,
  httpErrorOnPage,
  identifyOnGuestIncentiveSubmit,
  productPurchased,
  reportDelayedRequest,
  trackOnGuestIncentiveSubmit,
  viewNotFoundPage,
  viewPromotionPage,
  viewRecommendedListings,
  viewSearchMap,
  viewSearchResultsPage,
  paymentFailed,
  invalidQuote,
}

export default function useAnalytics() {
  return ANALYTICS_METHODS
}
