import { trackOnClient } from 'hooks/useAnalytics/AnalyticsTransmitter'

import { PageStatus } from 'components/PromotionPage/types'

export type GuestIncentiveTrackParams = {
  promotionId: string
  response: 'Success' | 'Fail'
  reason?: 'Already Enrolled' | 'Evolver' | 'Fail'
  pageStatus: PageStatus
}

type ErrorTypes = Exclude<
  GuestIncentiveTrackParams['pageStatus'],
  'initial' | 'success'
>

export const PAGE_STATUS_TO_SEGMENT_ERROR_REASON_MAP: {
  [key in ErrorTypes]: GuestIncentiveTrackParams['reason']
} = {
  userRegisteredError: 'Already Enrolled',
  employeeError: 'Evolver',
  genericError: 'Fail',
}

export default function trackOnGuestIncentiveSubmit(
  data: GuestIncentiveTrackParams,
) {
  const { pageStatus, ...rest } = data

  const reason = PAGE_STATUS_TO_SEGMENT_ERROR_REASON_MAP[pageStatus]
  if (!reason) {
    trackOnClient('Promotion Submitted', rest)
  } else {
    trackOnClient('Promotion Submitted', {
      ...rest,
      reason,
    })
  }
}
