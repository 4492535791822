import { page } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type PageEvent = {
  path: string
  previousPage?: string | null
  title: string
  url: string
}

export default function viewNotFoundPage(data: PageEvent) {
  const previousPage = data.previousPage

  page({
    name: '404 Page',
    referrer: previousPage,
    payload: {
      url: data.url,
      path: data.path,
      title: data.title,
    },
  })
}
